import cs from 'classnames';
import { Row, Col } from '@smart-kasa/ui';

import styles from './Receipt.module.scss';

interface IReceiptRowProps {
  title: any;
  value: any;
  className?: string;
}

export const ReceiptRow = ({ title, value, className }: IReceiptRowProps) => {
  if (!value) return null;

  return (
    <Row className={cs(styles.row, className)}>
      <Col span={14} className={cs(styles.column, styles.rowTitle)}>
        {title}
      </Col>
      <Col span={10} className={cs(styles.column, styles.rowValue)}>
        {value}
      </Col>
    </Row>
  );
};
