import { Fragment, useRef } from 'react';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { ButtonIcon, Card, Icon, Preloader } from '@smart-kasa/ui';

import useResource from 'hooks/useResource';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { receiptsAPI } from 'services/api/receiptsAPI';

import { Document } from 'features/documents/components/Document';
import { DocumentNotFound } from 'features/documents/components/DocumentNotFound';
import styles from './DocumentShow.module.scss';

export const DocumentShow = () => {
  const { id } = useParams();
  const documentRef = useRef<HTMLDivElement>(null);
  const { data: receipt, isLoading } = useResource(receiptsAPI.item, String(id));
  const [copy] = useCopyToClipboard();
  const print = useReactToPrint({ content: () => documentRef.current });

  if (isLoading) return <Preloader />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {receipt ? (
          <Fragment>
            <Card>
              <Document receipt={receipt} ref={documentRef} />
            </Card>

            <div className={styles.actions}>
              <ButtonIcon onClick={() => copy(window.location.href)}>
                <Icon name="copy" size="large" />
              </ButtonIcon>
              <ButtonIcon onClick={print}>
                <Icon name="printer" size="large" />
              </ButtonIcon>
            </div>
          </Fragment>
        ) : (
          <DocumentNotFound />
        )}
      </div>
    </div>
  );
};
