import '@smart-kasa/ui/dist/esm/bundle.css';
import 'components/global.module.scss';

import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { TranslationContextProvider } from 'contexts/TranslationContext';
import { ConfigContext } from 'contexts/ConfigContext';
import Router from 'routes/Router';

const App = () => {
  const { config } = useContext(ConfigContext);

  return (
    <TranslationContextProvider lang={config.locale}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </TranslationContextProvider>
  );
};

export default App;
