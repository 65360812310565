import { useRef, useEffect, useState } from 'react';
import JsBarcode from 'jsbarcode';

export interface IBarcodeProps {
  value: string;
  options?: IBarcodeOptions;
}

export interface IBarcodeOptions {
  format?: string;
  width?: number;
  height?: number;
  displayValue?: boolean;
  text?: string;
  fontOptions?: string;
  font?: string;
  textAlign?: string;
  textPosition?: string;
  textMargin?: number;
  fontSize?: number;
  background?: string;
  lineColor?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  flat?: boolean;
}

export function useBarcode({ value, options }: IBarcodeProps): any {
  const inputRef = useRef();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (inputRef) {
      const ref = inputRef as any;

      JsBarcode(ref.current, value, {
        ...options,
        valid: valid => setIsValid(valid),
      });
    }
  }, [value, options]);

  return { inputRef, isValid };
}
