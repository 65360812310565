import { Route, Routes } from 'react-router';

import { AppLayout } from 'layouts/AppLayout';

import { Documents } from 'features/documents';

const Router = () => (
  <Routes>
    <Route element={<AppLayout />}>
      <Route path="*" element={<Documents />} />
    </Route>
  </Routes>
);

export default Router;
