export enum ClassifierType {
  NONE = 0,
  UKTZED = 1,
  DKPP = 2,
}

interface IClassifierTypeProps {
  key?: number;
  value: number;
  label: string;
}

export const CLASSIFIER_TYPE_MAPPING: Record<ClassifierType, IClassifierTypeProps> = {
  [ClassifierType.NONE]: {
    value: ClassifierType.NONE,
    label: 'Не обрано',
  },
  [ClassifierType.UKTZED]: {
    value: ClassifierType.UKTZED,
    label: 'УКТЗЕД',
  },
  [ClassifierType.DKPP]: {
    value: ClassifierType.DKPP,
    label: 'ДКПП',
  },
};

export const classifierTypeOptions = Object.values(CLASSIFIER_TYPE_MAPPING);
