import humps from 'humps';
import type { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';

import { HttpClient } from './client';

const { REACT_APP_API_BASE_URL, REACT_APP_API_KEY = '' } = process.env;

export type HttpAdapter = {
  request: AxiosRequestTransformer;
  response: AxiosResponseTransformer;
};

const adapter: HttpAdapter = {
  request: humps.decamelizeKeys,
  response: (data) =>
    humps.camelizeKeys(data, (key, convert) => (/^[a-zA-Z0-9_]+$/.test(key) ? convert(key) : key)),
};

const client = new HttpClient({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    'X-Api-Key': REACT_APP_API_KEY,
  },
  transformRequest: adapter.request,
  transformResponse: adapter.response,
});

export { client };
