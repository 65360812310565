import { IShop, IPaymentTransaction } from 'types';

export enum ReceiptType {
  sale = 0,
  serviceInput = 1,
  serviceOutput = 2,
  refund = 3,
  reversal = 4,
  offlineStart = 102,
  offlineFinish = 103,
}

export enum ReceiptState {
  pending = 0,
  archived = 1,
  paid = 2,
  refunded = 3,
}

export interface IReceipt {
  id: string;
  type: ReceiptType;
  title?: string;
  description?: string;
  cashierName: string;
  fiscalNumber: string;
  registrarFiscalNumber: string;
  shiftId: string;
  taxAmount: IReceiptTax;
  totalAmount: number;
  roundingAmount: number;
  discountAmount: number;
  discountPercentage: number;
  rrn?: string;
  items?: IReceiptItem[];
  paymentTransactions?: IPaymentTransaction[];
  state: ReceiptState;
  terminalShiftId?: number;
  shop?: IShop;
  shopId: number;
  terminalId: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface IReceiptItem {
  id?: string;
  sku?: string;
  name: string;
  description?: string;
  quantity: number;
  price: number;
  amount: number;
  discountAmount?: number;
  taxGroupId?: number;
  classifierTypeId?: number;
  classifierCode?: string;
  exciseLabel?: string;
  isProduct?: boolean;
  productId: string;
  receiptId?: string;
  unitTypeId?: number;
  unitTypeName?: string;
  stockItemId?: number;
  isFreePrice?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

interface IReceiptTax {
  [key: string]: number;
}
