import { Fragment } from 'react';

export interface ICardHolder {
  value: string;
}

const regex = new RegExp('.{1,4}', 'g');

export const CardHolder = ({ value }: ICardHolder) => (
  <Fragment>{value.match(regex)?.join(' ')}</Fragment>
);
