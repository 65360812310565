import { FC, HTMLAttributes, forwardRef } from 'react';
import cs from 'classnames';
import styles from './Receipt.module.scss';

interface IReceiptWrapperProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  ref?: any;
}

export const ReceiptWrapper: FC<IReceiptWrapperProps> = forwardRef<
  HTMLDivElement,
  IReceiptWrapperProps
>(({ className, ...rest }, ref) => (
  <div {...rest} ref={ref} className={cs(styles.wrapper, className)} />
));
