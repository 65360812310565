import { FC, ReactNode } from 'react';
import cs from 'classnames';
import { Icon, IconName } from '@smart-kasa/ui';

import { getFileUrl } from 'utils/getFileUrl';
import { Colors } from 'contants/colors';
import { IFile } from 'types';

import styles from './Picture.module.scss';

export type PictureColor = Colors;

interface IPictureProps {
  src?: string | IFile | null;
  alt?: string;
  icon?: IconName;
  text?: string | ReactNode;
  size?: 'S' | 'M' | 'L' | 'XL';
  radius?: 'small' | 'default' | 'full';
  style?: {};
  className?: string;
  responsive?: boolean;
  fill?: PictureColor;
}

export const Picture: FC<IPictureProps> = ({
  fill = 'blue',
  radius,
  src,
  alt,
  icon = 'user',
  text,
  size = 'M',
  style,
  className,
  responsive,
}) => (
  <div
    style={{ ...style }}
    className={cs(
      styles.avatar,
      [styles[`size${size}`]],
      [styles[`${radius}Radius`]],
      [styles[fill]],
      className,
      {
        [styles.responsive]: responsive,
        [styles.source]: src,
      }
    )}
  >
    <div className={styles.content}>
      {src ? (
        <img
          className={styles.image}
          src={typeof src === 'string' || src instanceof String ? src : getFileUrl(src)}
          alt={alt}
        />
      ) : text ? (
        <div className={styles.text}>{text}</div>
      ) : (
        <Icon name={icon} color="white" size={size === 'XL' ? 'xxx-large' : 'large'} />
      )}
    </div>
  </div>
);
