import ReactDOM from 'react-dom';

import { ConfigContextProvider } from 'contexts/ConfigContext';
import App from './App';
import reportWebVitals from './reportWebVitals';

const { PUBLIC_URL, REACT_APP_API_BASE_URL, REACT_APP_API_KEY, REACT_APP_DEFAULT_LOCALE } =
  process.env;
const basename = PUBLIC_URL ? new URL(PUBLIC_URL).pathname : '/';

export const ROOT_NODE_ID = 'root';

if (!REACT_APP_API_BASE_URL) {
  throw new Error('"REACT_APP_API_BASE_URL" environment variable is empty');
}

if (!REACT_APP_API_KEY) {
  throw new Error('"REACT_APP_API_KEY" environment variable is empty');
}

if (!REACT_APP_DEFAULT_LOCALE) {
  throw new Error('"REACT_APP_DEFAULT_LOCALE" environment variable is empty');
}

const app = (
  <ConfigContextProvider
    config={{
      locale: REACT_APP_DEFAULT_LOCALE,
      basename,
    }}
  >
    <App />
  </ConfigContextProvider>
);

ReactDOM.render(app, document.getElementById(ROOT_NODE_ID));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
