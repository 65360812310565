import { HttpClient } from './http/client';
import { client as httpClient } from './http';

class APIService {
  client: HttpClient;

  constructor(client: HttpClient = httpClient) {
    this.client = client;
  }
}

export default APIService;
