import { FC, ReactNode, forwardRef } from 'react';
import { Title } from '@smart-kasa/ui';

import type { IReceipt } from 'types';

import { Receipt } from './Receipt';
import { ReceiptWrapper } from './ReceiptWrapper';
import { ReceiptHeader } from './ReceiptHeader';
import { ReceiptTransaction } from './ReceiptTransaction';
import { ReceiptFooter } from './ReceiptFooter';
import { ReceiptShop } from './ReceiptShop';

interface IDocumentProps {
  ref?: any;
  receipt: IReceipt;
  actions?: ReactNode;
  children?: never;
}

export const Document: FC<IDocumentProps> = forwardRef<HTMLDivElement, IDocumentProps>(
  ({ receipt, actions }, ref) => (
    <ReceiptWrapper ref={ref}>
      <ReceiptHeader actions={actions}>
        <Title>{receipt.fiscalNumber ? 'Фіскальний чек' : 'Товарний чек'}</Title>
      </ReceiptHeader>

      {receipt.shop && <ReceiptShop shop={receipt.shop} />}

      <Receipt receipt={receipt}>
        {receipt.paymentTransactions?.map((transaction) => (
          <ReceiptTransaction key={transaction.id} transaction={transaction} />
        ))}
      </Receipt>

      <ReceiptFooter />
    </ReceiptWrapper>
  )
);
