import { Col, Hint, Row, Strong } from '@smart-kasa/ui';

import { Picture } from 'components';
import type { IShop } from 'types';

import styles from './Receipt.module.scss';

interface IReceiptShopProps {
  shop: IShop;
}

export const ReceiptShop = ({ shop }: IReceiptShopProps) => (
  <Row gutter={10} className={styles.shop}>
    <Col>
      <Picture src={shop.picture} size="L" icon="business" className={styles.logo} />
    </Col>
    <Col>
      <Hint margin={0}>
        <Strong>{shop.title}</Strong>
      </Hint>
      {shop.address && <Hint margin={0}>{shop.address.content}</Hint>}
      <Hint margin={0}>
        {shop.isVatTaxation ? 'ПН' : 'ІД'}: {shop.taxpayerNumber}
      </Hint>
    </Col>
  </Row>
);
