import { useEffect } from 'react';
import qs from 'query-string';

import { ClientDefaultServerResponse } from 'services/http/client';

import { useRequest, UseRequestResult } from './useRequest';

function useResource<Result>(
  api: () => Promise<ClientDefaultServerResponse<Result>>
): UseRequestResult<Result>;
function useResource<T, Result>(
  api: (params: T) => Promise<ClientDefaultServerResponse<Result>>,
  params: T
): UseRequestResult<Result>;
function useResource<T, Result>(
  api: (params?: T) => Promise<ClientDefaultServerResponse<Result>>,
  params?: T
): UseRequestResult<Result> {
  const request = useRequest<T, Result>(api, params);
  const config = params && qs.stringify(params);

  useEffect(() => {
    request.load();
    // eslint-disable-next-line
  }, [config]);

  return request;
}

export default useResource;
