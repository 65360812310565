import { UAH } from '@smart-kasa/ui';

import { TAX_LETTER_MAPPING } from 'contants/taxGroup';

import { ReceiptRow } from './ReceiptRow';

interface ITaxProps {
  letter: string;
  amount: number;
}

export const ReceiptTax = ({ letter, amount }: ITaxProps) => {
  const taxLetter = letter.toUpperCase();
  const taxGroup = TAX_LETTER_MAPPING[taxLetter];

  return <ReceiptRow title={taxGroup} value={<UAH value={amount} kopecks={true} />} />;
};
