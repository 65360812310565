import React, { FC } from 'react';
import { useBarcode, IBarcodeOptions } from 'hooks/useBarcode';

interface IBarcodeProps extends IBarcodeOptions {
  value: string;
  className?: string;
}

export const Barcode: FC<IBarcodeProps> = ({ value, className, ...options }: IBarcodeProps) => {
  const { inputRef } = useBarcode({
    value,
    options: options,
  });

  return <svg ref={inputRef} className={className} />;
};

Barcode.defaultProps = {
  background: 'rgba(255, 255, 255, 0)',
  margin: 0,
  displayValue: false,
  fontSize: 16,
  height: 40,
};
