export enum TaxGroup {
  GENERAL = 0,
  PDV_20 = 1,
  PDV_7 = 2,
  PDV_0 = 3,
  BEZ_PDV = 4,
  AKTSYZ_5 = 5,
  AKTSYZ_5_PDV_20 = 6,
}

export enum TaxLetter {
  GENERAL = '',
  PDV_20 = 'А',
  PDV_7 = 'Б',
  PDV_0 = 'В',
  BEZ_PDV = 'Д',
  AKTSYZ_5 = 'З',
  AKTSYZ_5_PDV_20 = 'ЗА',
}

export const TAX_LETTER_MAPPING: Record<TaxLetter, string> = {
  [TaxLetter.GENERAL]: 'Не обрано',
  [TaxLetter.PDV_20]: 'ПДВ (А) 20%',
  [TaxLetter.PDV_7]: 'ПДВ (Б) 7%',
  [TaxLetter.PDV_0]: 'ПДВ (В) 0%',
  [TaxLetter.BEZ_PDV]: 'Без ПДВ (Д)',
  [TaxLetter.AKTSYZ_5]: 'Акциз (З) 5%',
  [TaxLetter.AKTSYZ_5_PDV_20]: 'Акциз (З) 5% + ПДВ (А) 20%',
};

interface ITaxGroupMapping {
  value: number;
  letter: TaxLetter;
  label: string;
}

export const TAX_GROUP_MAPPING: Record<TaxGroup, ITaxGroupMapping> = {
  [TaxGroup.GENERAL]: {
    value: TaxGroup.GENERAL,
    letter: TaxLetter.GENERAL,
    label: TAX_LETTER_MAPPING[TaxLetter.GENERAL],
  },
  [TaxGroup.PDV_20]: {
    value: TaxGroup.PDV_20,
    letter: TaxLetter.PDV_20,
    label: TAX_LETTER_MAPPING[TaxLetter.PDV_20],
  },
  [TaxGroup.PDV_7]: {
    value: TaxGroup.PDV_7,
    letter: TaxLetter.PDV_7,
    label: TAX_LETTER_MAPPING[TaxLetter.PDV_7],
  },
  [TaxGroup.PDV_0]: {
    value: TaxGroup.PDV_0,
    letter: TaxLetter.PDV_0,
    label: TAX_LETTER_MAPPING[TaxLetter.PDV_0],
  },
  [TaxGroup.BEZ_PDV]: {
    value: TaxGroup.BEZ_PDV,
    letter: TaxLetter.BEZ_PDV,
    label: TAX_LETTER_MAPPING[TaxLetter.BEZ_PDV],
  },
  [TaxGroup.AKTSYZ_5]: {
    value: TaxGroup.AKTSYZ_5,
    letter: TaxLetter.AKTSYZ_5,
    label: TAX_LETTER_MAPPING[TaxLetter.AKTSYZ_5],
  },
  [TaxGroup.AKTSYZ_5_PDV_20]: {
    value: TaxGroup.AKTSYZ_5_PDV_20,
    letter: TaxLetter.AKTSYZ_5_PDV_20,
    label: TAX_LETTER_MAPPING[TaxLetter.AKTSYZ_5_PDV_20],
  },
};

export const taxGroupOptions = Object.values(TAX_GROUP_MAPPING);
