import { ReactNode } from 'react';

import styles from './Receipt.module.scss';

interface IReceiptHeaderProps {
  actions?: ReactNode;
  children?: ReactNode;
}

export const ReceiptHeader = ({ actions, children }: IReceiptHeaderProps) => (
  <div className={styles.header}>
    {children}

    <div className={styles.headerActions}>{actions}</div>
  </div>
);
