import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponseTransformer } from 'axios';

export type ClientDefaultServerResponse<T> = { data: T; meta?: any };

export class HttpClient {
  private options: AxiosRequestConfig;
  private instance: AxiosInstance | null = null;

  constructor(options: AxiosRequestConfig = {}) {
    this.options = options;
  }

  get adapter(): AxiosInstance {
    return this.instance != null ? this.instance : this.init();
  }

  init() {
    const { transformRequest, transformResponse, ...config } = this.options;

    const http = axios.create({
      transformRequest: [
        ...(axios.defaults.transformRequest as AxiosResponseTransformer[]),
        transformRequest as AxiosResponseTransformer,
      ],
      transformResponse: [
        ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
        transformResponse as AxiosResponseTransformer,
      ],
      ...config,
    });

    this.instance = http;

    return http;
  }

  interceptors = () => this.adapter.interceptors;

  get = <T = any, R = ClientDefaultServerResponse<T>>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<R> => this.request({ ...config, url, method: 'GET', params });

  post = <T = any, R = ClientDefaultServerResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> => this.request({ ...config, url, method: 'POST', data });

  put = <T = any, R = ClientDefaultServerResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> => this.request({ ...config, url, method: 'PUT', data });

  delete = <T = any, R = ClientDefaultServerResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> => this.request({ ...config, url, method: 'DELETE' });

  private request = <T = any, R = ClientDefaultServerResponse<T>, D = any>(
    config: AxiosRequestConfig<D>
  ): Promise<R> => this.adapter.request(config).then((response) => response.data);
}
