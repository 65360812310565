import { Fragment } from 'react';
import { FormattedDate } from 'react-intl';
import { UAH } from '@smart-kasa/ui';

import { CardHolder } from 'components';
import { TransactionType, IPaymentTransaction } from 'types';

import { ReceiptGroup } from './ReceiptGroup';
import { ReceiptRow } from './ReceiptRow';

export interface IReceiptTransactionProps {
  transaction: IPaymentTransaction;
}

const PAYMENT_METHOD: Record<TransactionType, string> = {
  [TransactionType.cash]: 'Готівка',
  [TransactionType.cashless]: 'Картка',
  [TransactionType.nonCash]: 'Безготівка',
};

export const ReceiptTransaction = ({ transaction }: IReceiptTransactionProps) => (
  <ReceiptGroup>
    <ReceiptRow title="Вид оплати" value={PAYMENT_METHOD[transaction.transactionTypeId]} />
    <ReceiptRow title="Сума" value={<UAH value={transaction.amount} />} />

    {transaction.transactionTypeId === TransactionType.cash && (
      <Fragment>
        <ReceiptRow title="Сплачено" value={<UAH value={transaction.depositAmount} />} />
        <ReceiptRow title="Решта" value={<UAH value={transaction.changeAmount} />} />
      </Fragment>
    )}

    <ReceiptRow title="Платіжна система" value={transaction.paymentNetworkId} />
    <ReceiptRow title="ЕПЗ" value={transaction.pan && <CardHolder value={transaction.pan} />} />
    <ReceiptRow title="Банк-еквайєр" value={transaction.payload?.bank} />
    <ReceiptRow title="Код авторизації" value={transaction.approvalCode} />
    <ReceiptRow title="MID" value={transaction.payload?.merchantId} />
    <ReceiptRow title="TID" value={transaction.payload?.terminalId} />
    <ReceiptRow
      title="Дата та час"
      value={<FormattedDate value={transaction.timestamp} dateStyle="short" timeStyle="short" />}
    />
    <ReceiptRow title="RRN" value={transaction.rrn} />
  </ReceiptGroup>
);
