import { useCallback, useState } from 'react';

import { ClientDefaultServerResponse } from 'services/http/client';

export type UseRequestResult<Result> = {
  data?: Result;
  meta?: any;
  isLoading: boolean;
  error: any;
  load: () => Promise<void>;
};

const useRequest = <T, Result>(
  api: (params?: T) => Promise<ClientDefaultServerResponse<Result>>,
  params?: T
): UseRequestResult<Result> => {
  const [data, setData] = useState<Result>();
  const [meta, setMeta] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | unknown>();

  const load = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api(params);
      setData(response.data);
      if (response.meta) {
        setMeta(response.meta);
      }
    } catch (err) {
      setError(err);
    }

    setIsLoading(false);
  }, [setIsLoading, setError, api, params]);

  return {
    data,
    meta,
    isLoading,
    error,
    load,
  };
};

export { useRequest };
