import { IFile, IReceipt } from 'types';

export enum PaymentNetwork {
  undefined = 0,
  mastercard = 5,
  visa = 4,
  americanExpress = 3,
  prostir = 9,
  unionPay = 6,
  cash = 100,
  other = 101,
}

export enum TransactionPaymentType {
  pay = 0,
  reversal = 1,
  refund = 2,
}

export enum TransactionType {
  cash = 0,
  cashless = 1,
  nonCash = 2,
}

export interface IPaymentTransaction {
  id: string;
  state: number;
  rrn: string;
  amount: string;
  changeAmount: string;
  depositAmount: string;
  pan?: string;
  approvalCode?: string;
  resultCode?: string;
  picture?: IFile;
  payload?: {
    bank: string;
    merchantName: string;
    merchantId: string;
    terminalId: string;
    maskedPan: string;
  };
  paymentNetworkId?: PaymentNetwork;
  paymentTypeId: TransactionPaymentType;
  receipt?: IReceipt;
  receiptId: string;
  transactionTypeId: TransactionType;
  timestamp: string;
  createdAt: string;
  updatedAt: string;
}
