import { Card, Hint, Icon, Paragraph } from '@smart-kasa/ui';

import { BlankView } from 'components';

export const DocumentNotFound = () => (
  <Card>
    <BlankView>
      <Paragraph>
        <Icon name="receipt" size="x-large" />
      </Paragraph>
      Чек не знайдено
      <Hint>
        Можливо операція була здійснена в режимі 'Офлайн' та чек ще не був надісланий до системи.
        Спробуйте завантажити сторінку пізніше.
      </Hint>
    </BlankView>
  </Card>
);
