import { FC, Fragment } from 'react';
import { Hint, UAH } from '@smart-kasa/ui';

import type { IReceiptItem } from 'types';
import { TAX_GROUP_MAPPING } from 'contants/taxGroup';
import { ClassifierType } from 'contants/classifierType';

import { ReceiptRow } from './ReceiptRow';

export const ReceiptItem: FC<{ item: IReceiptItem }> = ({ item }) => {
  const taxGroup = !!item.taxGroupId && TAX_GROUP_MAPPING[item.taxGroupId];

  return (
    <ReceiptRow
      title={
        <Fragment>
          {item.name}
          <Hint margin={0}>{item.description}</Hint>
          {item.classifierTypeId === ClassifierType.UKTZED && (
            <Hint margin={0}>Код УКТЗЕД: {item.classifierCode}</Hint>
          )}
          {item.exciseLabel && <Hint margin={0}>Акцизна марка: {item.exciseLabel}</Hint>}
        </Fragment>
      }
      value={
        <Fragment>
          <UAH value={item.amount} /> {taxGroup && taxGroup.letter}
          <Hint margin={0}>
            {item.quantity}
            {item.unitTypeName} x <UAH value={item.price} />
          </Hint>
        </Fragment>
      }
    />
  );
};
